import React, {useEffect, useMemo, useState} from "react";
import {getReport} from "../../connection/firebase";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import {PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image} from '@react-pdf/renderer';
import IconButton from "@mui/material/IconButton";
import ExportPdf from '@mui/icons-material/FileDownload';
import SoftBox from "../../components/SoftBox";
import Grid from "@mui/material/Grid";
import DatePicker from "react-date-picker";
import SoftButton from "../../components/SoftButton";
import Table from "../../examples/Tables/Table";
import SoftAlert from "../../components/SoftAlert";
import logo from "../../assets/images/maximLogo.png";
const Columns = [
    { name: 'Unique Calls', align: 'center' },
    { name: 'Total Calls', align: 'center' }
];



function Report() {
    const currentDate = new Date();
    const monthPriorDate = new Date();
    monthPriorDate.setMonth(monthPriorDate.getMonth());
    const currentDateString = currentDate.toISOString().split('T')[0];
    const monthPriorDateString = monthPriorDate.toISOString().split('T')[0];
    const [dateFrom, setDateFrom] = useState(monthPriorDate);
    const [dateTo, setDateTo] = useState(currentDate);
    const [hasRecord, setHasRecord] = useState(false);
    const [rows, setRows] = useState([{['Unique Calls']: 0,['Total Calls']: 0}]);

    const styles = StyleSheet.create({
        body: {
            padding: 10,
        },
        table: {
            display: 'table',
            width: 'auto',
            borderStyle: 'solid',
            borderColor: 'white',
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0,
        },
        tableRow: {
            margin: 'auto',
            flexDirection: 'row',
        },
        tableColHeader: {
            width: '25%',
            borderStyle: 'solid',
            borderColor: 'white',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tableColTextRight: {
            backgroundColor: '#f5f5f5',
            width: '35%',
            borderStyle: 'solid',
            borderColor: 'white',
            borderWidth: 0,
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderBottomWidth: 1,
        },
        tableColTextLeft: {
            backgroundColor: '#f5f5f5',
            width: '35%',
            borderStyle: 'solid',
            borderColor: 'white',
            borderWidth: 0,
            borderLeftWidth: 1,
            borderBottomWidth: 1,
        },
        tableCol: {
            width: '50%',
            borderStyle: 'solid',
            borderColor: 'white',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tableColTitle: {
            backgroundColor: '#034787',
            width: '35%',
            borderStyle: 'solid',
            borderColor: 'white',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            color: 'white',
        },
        tableCellHeader: {
            margin: 'auto',
            fontSize: 12,
            fontWeight: 500,
        },
        tableCell: {
            padding: '10px',
            fontSize: 10,
            textAlign: 'center',
        },
        spaceBetween: {
            padding: '10px 0px',
        },
        textTitle: {
            backgroundColor: '#034787',
            textAlign: 'center',
            color: 'white',
            fontWeight: 'bold',
            width: '70%',
            marginLeft: '15%',
        },
        textSubtitle: {
            backgroundColor: '#034787',
            textAlign: 'center',
            color: 'white',
            fontWeight: 'bold',
            fontSize: 12,
            marginBottom: '30px',
            width: '70%',
            marginLeft: '15%',
        },
        logo: {
            width: '200px',
            height: 'auto',
            alignSelf: "center",
            marginBottom: '15px',
        }
    });

    const MyDoc = () => (
        <Document>
            <Page style={styles.body}>
                <View>
                    <Image style={styles.logo} src={logo} />
                    <Text style={styles.textTitle}>Calls Report</Text>
                    <Text style={styles.textSubtitle}>Period: {dateFrom.getMonth()+1}/{dateFrom.getFullYear()} - {dateTo.getMonth()+1}/{dateTo.getFullYear()}</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColTitle}>
                            <Text style={styles.tableCell}>Unique Calls</Text>
                        </View>
                        <View style={styles.tableColTitle}>
                            <Text style={styles.tableCell}>Total Calls</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColTextRight}>
                            <Text style={styles.tableCell}>{rows[0]['Unique Calls']}</Text>
                        </View>
                        <View style={styles.tableColTextRight}>
                            <Text style={styles.tableCell}>{rows[0]['Total Calls']}</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );

    const DownloadPdf = () => {
        return useMemo(
            () => (
                <PDFDownloadLink
                    document={<MyDoc />}
                    fileName="Maxim-calls-report.pdf"
                >
                    {({ loading }) =>
                        loading ? (
                            'loading...'
                        ) : (
                            <div>
                                <IconButton>
                                    <ExportPdf color={"action"} fontSize="medium" />
                                </IconButton>
                            </div>
                        )
                    }
                </PDFDownloadLink>
            ),
            [rows]
        );
    };

    const onChangeDate = (date, name) => {
        if (name === "fromDate") {
            setDateFrom(date);
        } else {
            setDateTo(date);
        }
    };

    const handleSearch = async () => {
        const obj = {
            startDate: dateFrom,
            endDate: dateTo
        }
        const result = await getReport(obj);
        if (result.overallCount > 0 && result.uniqueCount > 0){
            let tempRows = [];
            let tempRow = {
                [`Unique Calls`]: result.uniqueCount,
                [`Total Calls`]: result.overallCount
            }
            tempRows.push(tempRow);
            setRows(tempRows);
            setHasRecord(true);
        } else {
            setHasRecord(false);
        }
    }

    useState(() => {
        handleSearch();
    });

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <Grid container>
                    <Grid item xs={6} sm={3} xl={3} mr={2} mb={2}>
                        <DatePicker
                            value={dateFrom}
                            format={"y-MM"}
                            onChange={(e) => {
                                onChangeDate(e, "fromDate");
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3} xl={3} mr={2} mb={2}>
                        <DatePicker
                            value={dateTo}
                            format={"y-MM"}
                            onChange={(e) => {
                                onChangeDate(e, "toDate");
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3} xl={3} mx={2}>
                        <SoftButton color="dark" onClick={handleSearch}>Search</SoftButton>
                    </Grid>
                </Grid>
                {hasRecord ? (
                    <>
                        <Grid container my={3}>
                            <Grid item xs={12} sm={2} cl={2} ml={1}>
                                <h3>Calls</h3>
                            </Grid>
                            <Grid item xs={12} sm={2} cl={2} mr={2}>
                                <DownloadPdf/>
                            </Grid>
                        </Grid>
                        <Grid container>
                        <SoftBox
                                sx={{
                                    "& .MuiTableRow-root:not(:last-child)": {
                                        "& td": {
                                            borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                                `${borderWidth[1]} solid ${borderColor}`,
                                        },
                                    },
                                }}
                                mb={3}
                            >
                                <Table columns={Columns} rows={rows} />
                            </SoftBox>
                        </Grid>
                    </>
                ) : (
                    <SoftAlert color="error" dismissible>No report found between those dates</SoftAlert>
                )}
            </SoftBox>
        </DashboardLayout>
    )
}

export default Report;