let BACKEND_SERVER = null;
if (process.env.REACT_APP_BACKEND_SERVER) {
  // BACKEND_SERVER = "http://localhost:8080/api/";
  BACKEND_SERVER = "https://api-staging.maximpricingtool.com/api/";
} else {
  // BACKEND_SERVER = "http://localhost:8080/api/";
  BACKEND_SERVER = "https://api-staging.maximpricingtool.com/api/";
}

export const API_SERVER = BACKEND_SERVER;
export const ADMIN = 1;
export const USER = 2;
