import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import SoftBox from "../../components/SoftBox";
import React, {useEffect, useState} from "react";
import SoftTypography from "../../components/SoftTypography";
import {get_deals} from "../../connection/bridgeService";
import Table from "../../examples/Tables/Table";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SoftButton from "../../components/SoftButton";
import {useNavigate} from "react-router-dom";
import SoftInput from "../../components/SoftInput";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import Typography from "@mui/material/Typography";
import DatePicker from "react-date-picker";
import "./newUI.css";
import "react-calendar/dist/Calendar.css";
import {getCities, getDeals} from "../../connection/firebase";

function Deals() {
    const navigate = useNavigate();
    const [deals, setDeals] = useState([]);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const currentDate = new Date();
    const currentDateString = currentDate.toISOString().split('T')[0];
    const monthPriorDate = new Date();
    monthPriorDate.setMonth(monthPriorDate.getMonth() - 1);
    const monthPriorDateString = monthPriorDate.toISOString().split('T')[0];
    const [dateFrom, setDateFrom] = useState(monthPriorDateString);
    const [dateTo, setDateTo] = useState(currentDateString);
    const [page, setPage] = useState(1);
    const [groupById, setGroupById] = useState([]);
    const [idList, setIdList] = useState([]);

    const parseIntNumberWithSeparator = (value) => {
        if(value === undefined || value === null) return 0;
        let numericValue = 0;
        if (typeof value === 'string') {
            numericValue = value.replace(/\D/g, '');
        } else {
            numericValue = value;
        }
        return parseInt(numericValue, 10);
    };


    const onChangeDate = (date, name) => {
        if (name === "fromDate") {
            setDateFrom(date);
        } else {
            setDateTo(date);
        }
    };

    const handleDeal = (deal) => {
        deal.root$amount = parseIntNumberWithSeparator(deal.root$amount);
        deal.root$down_payment = parseIntNumberWithSeparator(deal.root$down_payment);
        deal.root$nil = parseIntNumberWithSeparator(deal.root$nil);
        deal.pd = deal.pd/100;
        return navigate('/dashboard', {state: deal});
    };

    const handlePrev = () => {
        let tempPage = page;
        tempPage --;
        let params = {
            per_page: 10,
            from_date: dateFrom,
            to_date: dateTo,
            page: tempPage
        }
        setPage(tempPage);
        getInfo(params);
    };

    const handleNext = () => {
        let tempPage = page;
        tempPage ++;
        let params = {
            per_page: 10,
            from_date: dateFrom,
            to_date: dateTo,
            page: tempPage
        }
        setPage(tempPage);
        getInfo(params);
    };

    const formatPeriod = (value) => {
        const tempValue = (value % 1) !== 0 ?
            (value % 1) < 0.55 ? Math.floor(value) : Math.ceil(value)
            : value;
        const formattedValue = tempValue.toLocaleString().replace(/,/g, ',');
        return formattedValue;
    };

    const setTable = () => {
        let tempColumns = [
            { name:'Scoring Date', align: 'center' },
            { name:'Opportunity ID', align: 'left' },
            { name:'Opportunity Name', align: 'left' },
            { name:'Financed Amount', align: 'center' },
            { name:'Preliminary Decision', align: 'left' },
            { name:'Score', align: 'center' },
            { name:'PD', align: 'center' },
            { name:'PD Risk Rating', align: 'center' },
            { name:'Final Decision', align: 'left' },
            { name:'actions', align: 'center' },
        ];
        setColumns(tempColumns);
    }

    const getInfo = async (obj) => {
        const response = await get_deals(obj);
        let temData = response;
        let tempDeals = [];
        if (response) {
            temData.forEach(deal => {
                const dateStr = deal.executedAt;
                const dateObj = new Date(dateStr);
                const formattedDate = dateObj.toLocaleDateString("en-US", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                });
                const riskPer = (deal?.executionOutput?.score?.pd.value * 100).toFixed(2);
                const recType = deal.executionOutput.fields?.root$record_type ? deal.executionOutput.fields?.root$record_type.split(" ") : ['TR'];
                let tempDeal = {
                    key: deal.executionOutput.opportunity_id,
                    executedAt: formattedDate,
                    executionID: deal.executionID,
                    billableID: deal.billableID,
                    opportunityID: deal.executionOutput.opportunity_id,
                    opportunityName: deal.executionOutput.fields?.root$opportunity_name,
                    root$amount: formatPeriod(deal?.executionOutput.fields?.root$amount),
                    root$down_payment: formatPeriod(deal?.executionOutput.fields?.root$down_payment),
                    root$nil: formatPeriod(deal?.executionOutput.fields?.root$nil),
                    root$term: deal?.executionOutput.fields?.root$term,
                    final_decision: deal?.executionOutput.final_decision,
                    scoring_engine_score: deal?.executionOutput.score?.scoring_engine_score,
                    pd: parseFloat(riskPer),
                    pd_risk_rating: deal?.executionOutput.score?.pd_risk_rating,
                    el_risk_grade: deal?.executionOutput.score?.el_risk_grade,
                    score_decision: deal?.executionOutput.score?.score_decision,
                    assets: deal?.executionOutput.fields?.root$assets,
                    root$record_type: recType[0],
                };
                tempDeals.push(tempDeal);
            })
            setDeals(tempDeals);
            setTable();
            const groupedData = tempDeals.reduce((result, obj) => {
                const opportunityID = obj.opportunityID;
                if (!result[opportunityID]) {
                    result[opportunityID] = [];
                }
                result[opportunityID].push(obj);
                return result;
            }, {});
            setGroupById(groupedData);
            const ids = Object.keys(groupedData);
            const idGroup = [];
            ids.map(id => {
                tempDeals.map(deal => {
                    if (deal.key === id) {
                        const tempDeal = {
                            id: id,
                            name: deal.opportunityName
                        };
                        idGroup.push(tempDeal);
                    }
                })
            });
            const reduceIdList = [];
            idGroup.map(idOrg => {
                if (!reduceIdList.find(id => id.id === idOrg.id)){
                    reduceIdList.push(idOrg);
                }
            });
            setIdList(reduceIdList);
            let formattedGroup = groupedData;
            ids.map(id => {
                const group = [];
                groupedData[id].map(deal => {
                    let tempRow = {
                        id: id,
                        ["Scoring Date"]: deal.executedAt,
                        ["Opportunity ID"]: deal.opportunityID,
                        ["Opportunity Name"]: deal.opportunityName ? deal.opportunityName : "NA",
                        ["Financed Amount"]: deal.root$nil,
                        ["Preliminary Decision"]: deal.score_decision?.value,
                        Score: deal.scoring_engine_score?.value,
                        PD: `${deal.pd}%`,
                        ["PD Risk Rating"]: deal.pd_risk_rating?.value,
                        ["Final Decision"]: deal.final_decision,
                        actions: (<IconButton onClick={() => {
                            handleDeal(deal);
                        }}><Icon>search</Icon></IconButton>)
                    }
                    group.push(tempRow);
                })
                formattedGroup[id] = group;
            });
            setRows(formattedGroup);
        }

    }

    const handleSearch = () => {
        const selectedDateFrom = new Date(dateFrom);
        selectedDateFrom.setUTCHours(23, 59, 0, 0)
        const selectedDateISOFrom = selectedDateFrom.toISOString();
        const selectedDateTo = new Date(dateTo);
        selectedDateTo.setUTCHours(23, 59, 0, 0)
        const selectedDateISOTo = selectedDateTo.toISOString();
        const tempPage = 1;
        let params = {
            per_page: 10,
            from_date: selectedDateISOFrom,
            to_date: selectedDateISOTo,
            page: tempPage
        }
        setPage(tempPage);
        getInfo(params);
    };

    useEffect(() => {
        const currentDate = new Date();
        const currentDateString = currentDate.toISOString().split('T')[0] + 'T00:00:00';
        const monthPriorDate = new Date();
        monthPriorDate.setMonth(monthPriorDate.getMonth() - 1);
        const monthPriorDateString = monthPriorDate.toISOString().split('T')[0] + 'T00:00:00';
        let obj = {
            per_page: 10,
            from_date: monthPriorDateString,
            to_date: currentDateString,
            page: page
        }
        getInfo(obj);
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox mb={3}>
                <Grid container>
                    <Grid item xs={6} sm={3} xl={3} mr={2} mb={2}>
                        <DatePicker
                            value={dateFrom}
                            onChange={(e) => {
                                onChangeDate(e, "fromDate");
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3} xl={3} mr={2} mb={2}>
                        <DatePicker
                            // onChange={(e) => {
                            //     const selectedDate = new Date(e);
                            //     const selectedDateUTC = selectedDate.toISOString();
                            //     onChangeDateFrom(selectedDateUTC);
                            // }}
                            // value={dateFrom}
                            value={dateTo}
                            onChange={(e) => {
                                onChangeDate(e, "toDate");
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3} xl={3} mx={2}>
                        <SoftButton color="dark" onClick={handleSearch}>Search</SoftButton>
                    </Grid>
                </Grid>
                <Grid mt={3}>
                    <Grid item mb={2}>
                        <SoftTypography sx={{marginLeft: "1rem", fontWeight: "bold"}}>Opportunity Name</SoftTypography>
                    </Grid>
                    <Grid item>
                        {idList?.map((id) => (
                                <>
                                    <Accordion>
                                        <AccordionSummary
                                            // expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            {id && id.name ? (
                                                <SoftBox sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                                    <SoftTypography>{id.name}</SoftTypography>
                                                    <SoftTypography>ID: {id.id} </SoftTypography>
                                                </SoftBox>
                                            ) : (
                                                <SoftTypography>Opportunity ID: {id.id} </SoftTypography>)}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <SoftBox
                                                sx={{
                                                    "& .MuiTableRow-root:not(:last-child)": {
                                                        "& td": {
                                                            borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                                                `${borderWidth[1]} solid ${borderColor}`,
                                                        },
                                                    },
                                                }}
                                            >
                                                <Table columns={columns} rows={rows[id.id]} />
                                            </SoftBox>
                                        </AccordionDetails>
                                    </Accordion>
                                </>
                            )
                        )}
                    </Grid>
                </Grid>
                {deals && deals.length >= 1 && (
                    <Grid container ml="auto">
                        <Grid item>
                            <SoftButton disabled={page === 1} variant="text" color="dark" size="small"
                                        onClick={() => {handlePrev();}}>Prev</SoftButton>
                        </Grid>
                        <Grid item>
                            <SoftTypography>{page}</SoftTypography>
                        </Grid>
                        <Grid item>
                            <SoftButton disabled={deals.length < 10} variant="text" color="dark" size="small"
                                        onClick={() => {handleNext();}}>Next</SoftButton>
                        </Grid>
                    </Grid>
                )}
            </SoftBox>
        </DashboardLayout>
    )
}

export default Deals;