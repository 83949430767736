import React, {useEffect} from "react";
import { useAuth } from './auth-context/auth.context';
import {useNavigate, Outlet, useSearchParams} from 'react-router-dom';

export const ProtectedRoute = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { user } = useAuth();

  const isAuthenticated = user && user.token !== null && user.token !== undefined && user.token !== '';

  useEffect(() => {
    if (location.pathname === '/direct-dashboard'){
      const execution_id = searchParams.get('execution_id');
      localStorage.setItem("execution_id",execution_id);
    }
    if (!isAuthenticated) {
      navigate("/authentication/sign-in");
    }
  }, [isAuthenticated, navigate]);

  return isAuthenticated ? <Outlet /> : null;
};
