import axios from "./index";
import { API_SERVER } from "config/constant";

class AuthApi {
  static Login = (data) => {
    //return axios.post(`${API_SERVER}auth`, data);
    const url = `${API_SERVER}auth`;
    return axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  };

  static Register = (data) => {
    const url = `${API_SERVER}account`;
    return axios.post(url, {
      name: data.username,
      email: data.email,
      password: data.password,
      confirm_password: null
    }, {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        //'Accept-Language': 'es-ES,es;q=0.9,en;q=0.8',
        //'Connection': 'keep-alive',
        'Content-Type': 'application/json',
        //'Origin': 'http://localhost:3000',
        //'Referer': 'http://localhost:3000/',
        //'Sec-Fetch-Dest': 'empty',
        //'Sec-Fetch-Mode': 'cors',
        //'Sec-Fetch-Site': 'same-site',
        //'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
        //'sec-ch-ua': '"Not.A/Brand";v="8", "Chromium";v="114", "Google Chrome";v="114"',
        //'sec-ch-ua-mobile': '?0',
        //'sec-ch-ua-platform': '"Windows"'
      }
    })

  };

  static Authorize = (code) => {
    return axios.get(`${API_SERVER}sessions/oauth/github?code=${code}`);
  };

  static Logout = (data) => {
    // return axios.post(`${API_SERVER}users/logout`, data, {
    //   headers: { Authorization: `${data.token}` },
    // });
    const url = `${API_SERVER}auth`;
    return axios.delete(url, {
      headers: {
        //'sec-ch-ua': '"Not.A/Brand";v="8", "Chromium";v="114", "Google Chrome";v="114"',
        'Accept': 'application/json, text/plain, */*',
        //'Referer': 'http://localhost:3000/',
        //'sec-ch-ua-mobile': '?0',
        'Authorization': `Bearer ${data.token}`,
        //'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36',
        //'sec-ch-ua-platform': '"Windows"'
      }
    })

  };
}

export default AuthApi;
